import './css/navbar.css';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef  } from "react";
import $ from 'jquery';

function Navbar(){

    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-eles").slideDown(400);
            $("nav").addClass("active");

            $(".nav-eles").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-eles").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-eles ul li").on("click", function() {
                $(".nav-eles").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    


    return(
        <>
            <nav>
                <div className='container'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="logo ucontent" />
                        </div>

                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-eles">
                        <button id="close-nav"> <i className="las la-times"></i> </button>

                            <ul>
                                <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink to="/about-us">
                                        About Us
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                        Services
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/our-products">
                                        Products
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/blogs">
                                        Blogs
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        Contact Us
                                    </RouterLink>
                                </li>

                                <li>
                                    <a href="/ar/">
                                        العربية
                                    </a>
                                </li>
                                
                            </ul>
                        </div>

                        <RouterLink to="/contact-us"> Contact Us </RouterLink>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;