import '../css/footer.css';
import Logo from '../images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import React from 'react';

function Footer(){


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    const location = useLocation(); // الحصول على الموقع الحالي من react-router

    useEffect(() => {
        // التمرير إلى أعلى الصفحة في كل مرة يتغير فيها المسار
        window.scrollTo(0, 0);
    }, [location.pathname]); // إعادة تشغيل هذا التأثير عند تغيير المسار

    return(
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <div className='logo'>
                                <img src={Logo} alt="شعار uc content" />
                            </div>

                            <p>
                                شركة المحتوى الفريد التقنية
                            </p>
                            <a href="mailto:info@ucontent.sa">   info@ucontent.sa </a>
                            <a href="https://ucontent.sa"> www.ucontent.sa </a>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> روابط سريعة </h1>
                            <ul>
                                <li>
                                    <RouterLink to="/ar/">
                                        الصفحة الرئيسية
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/about-us">
                                        من نحن
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        الخدمات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/our-products">
                                        المنتجات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/blogs">
                                        المدونة
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/contact-us">
                                        اتصل بنا
                                    </RouterLink>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> الخدمات </h1>
                            <ul>
                                <li>
                                    <RouterLink to="/ar/services">
                                        إدارة البيانات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        تحليلات البيانات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        الذكاء الاصطناعي وتعلم الآلة
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        حوكمة البيانات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        الخدمات الإحصائية
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        التدريب والتطوير
                                    </RouterLink>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> المدونة </h1>
                            <ul>
                                <li>
                                    <RouterLink to="/ar/blog?id=1">
                                        الاتجاهات والتوقعات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/blog?id=2">
                                        الاستفادة من البيانات الكبيرة
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/blog?id=3">
                                        تسويق وسائل التواصل الاجتماعي
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/blog?id=4">
                                        دور الذكاء الاصطناعي
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/blog?id=5">
                                        كيف تحسن تحليلات البيانات والذكاء الاصطناعي
                                    </RouterLink>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='copy-right'>
                            <h3> © 2023 شركة المحتوى الفريد كل الحقوق محفوظة. </h3>

                            <div className='socials'>
                                <a target='_blank' href="https://twitter.com/UcontentSA">
                                    <i class="lab la-twitter"></i>
                                </a>

                                <a target='_blank' href="https://www.linkedin.com/company/ucontentsa/">
                                    <i class="lab la-linkedin-in"></i>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )   
}

export default Footer;
