import './css/oursolutions.css';

function OurSolutions(){
    return(
        <>
            <div className='our-solutions'>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='our-solutions-content'>
                        <div className='text'>
                            <h4 data-aos="fade-right">  OUR SOLUTION </h4>
                            <h1 data-aos="fade-right"> Introducing Smart Solutions: Elevating Your Business to New Heights </h1>

                            <a data-aos="fade-right" href="mailto:info@ucontent.sa">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurSolutions;