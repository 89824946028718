import '../css/oursolutions.css';
import React from 'react';

function OurSolutions(){


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    
    return(
        <>
            <div className='our-solutions'>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='our-solutions-content'>
                        <div className='text'>
                            <h4 data-aos="fade-right">  حلولنا </h4>
                            <h1 data-aos="fade-right"> قدمنا لكم حلولًا ذكية: رفع أعمالكم إلى آفاق جديدة </h1>

                            <a data-aos="fade-right" href="mailto:info@ucontent.sa">
                                اتصل بنا
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurSolutions;
