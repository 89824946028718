import './css/landing.css';

import LandingVid from './videos/landing.mp4';

import { Link as RouterLink } from 'react-router-dom';

function Landing(){
    return(
        <>
            <section data-aos="zoom-in" className='landing'>
                <video  src={LandingVid} loop autoPlay muted></video>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='landing-content'>
                        <h1   data-aos-delay="1200" data-aos="fade-up"> Unique Content  </h1>
                        <h2 data-aos-delay="1200" data-aos="fade-up"> Strategy to New Heights </h2>
                        <p data-aos-delay="1200" data-aos="fade-up"> 
                            Experience the transformative potential of data through our unparalleled consulting services. Propel your business forward and enhance decision-making with precision.
                        </p>

                        <div data-aos-delay="1200" data-aos="fade-up">
                            <RouterLink to='/services'> Our Services </RouterLink>

                            <a href="mailto:info@ucontent.sa">
                                <i class="las la-play-circle"></i>
                                How to start ? 
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;