import '../css/blogpage.css';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { Link as RouterLink } from 'react-router-dom';

import PageHeaderImg from '../images/products/pageheader.webp';

import ProductImg1 from '../images/products/hawas.png';


function ProductPage() {


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    
const [blogsData, setBlogsData] = useState([
    {
        id: 1, 
        header: 'ثورة في تحليل البيانات والتسويق الرقمي في المملكة العربية السعودية', 
        img: ProductImg1, 
        sec_header1: 'المشكلة', 
        para1: 'أدوات تحليل متناثرة ونقص في الرؤى التسويقية الفعّالة. استراتيجيات تسويق رقمي غير فعّالة. تنوع في مصادر البيانات. تحليل سوقي معقد.', 
        sec_header2: 'حلاّنا', 
        para2: 'منصة شاملة تبسّط تحليل البيانات وتعزز فعالية التسويق الرقمي.', 
        para3: 'مشروع "حواس" هو منصة رقمية مبتكرة مصممة لتوفير حلاول تحليلية متقدمة للشركات في المملكة العربية السعودية، بهدف مساعدتهم في فهم وتحليل بياناتهم بطريقة أعمق وأدق. إنه يمكن الشركات من تحليل مجموعات البيانات الكبيرة بكفاءة وبسرعة باستخدام تقنيات الذكاء الاصطناعي، مما يتيح لها استخراج رؤى قيمة من بياناتها. بالإضافة إلى ذلك، يمكن استخدام "حواس" لتحسين الأداء التشغيلي من خلال تحليل البيانات المتعلقة بالإنتاج والتوريد وعمليات أخرى، مما يزيد من الكفاءة ويقلل من التكاليف.',
        para4: 'توفر المنصة أيضًا تحليلات حول سلوك العملاء وتفاعلهم مع المنتجات أو الخدمات، مما يتيح للشركات تحسين تجربة العملاء وزيادة الرضا. من خلال استخدام البيانات التاريخية والنماذج التنبؤية، يمكن لـ "حواس" تقديم توقعات دقيقة لاتجاهات السوق المستقبلية والطلب على المنتجات أو الخدمات.',
        para5: 'تقدم "حواس" واجهة مستخدم وتجربة مخصصة سهلة الاستخدام تسمح للمستخدمين بتحليل البيانات وإنشاء تقارير مخصصة. إنها تضمن أمان البيانات وسرية البيانات باستخدام أحدث التقنيات في حماية البيانات. ببساطة، "حواس" هو أداة قوية لتحليل البيانات واتخاذ قرارات استراتيجية مستندة إلى البيانات، مما يقدم العديد من المزايا للشركات الراغبة في تحسين أدائها والتفوق في بيئة الأعمال التنافسية.',
        sec_header4: 'مشكلة التكامل البياني: (المشكلة 1)',
        para6: 'يمكن لـ "حواس" دمج البيانات من مصادر تسويقية وإعلانية مختلفة، مثل منصات وسائل التواصل الاجتماعي، وشبكات الإعلانات، وأنظمة إدارة العلاقات مع العملاء، وغيرها، في منصة واحدة موحدة. يساعد ذلك الشركات في تسهيل إدارة البيانات.',
        sec_header5: 'تحديات تحليل البيانات: (المشكلة 2)',
        para7: 'توفر "حواس" أدوات لتحليل البيانات والتصوير البياني. يمكن للمسوقين استخدامها للحصول على رؤى حول أدائهم التسويقي وسلوك العملاء وفعالية الحملات.',
        sec_header6: 'قضايا تجربة المستخدم وواجهة المستخدم: (المشكلة 3)',
        para8: 'يسمح "حواس" للمستخدمين بإنشاء تقارير مخصصة ولوحات معلومات، مما يجعل من السهل تتبع المؤشرات الرئيسية للأداء ومشاركة الرؤى مع أصحاب المصلحة.',
        sec_header7: 'مشكلة تحديد السوق المستهدفة: (المشكلة 4)',
        para9: 'يمكن لـ "حواس" مساعدة المسوقين في تسنين التحويلات والمبيعات إلى قنوات التسويق أو الحملات الدعائية المحددة، مما يسمح بتحليل دقيق لعائد الاستثمار.',
        sec_header8: 'تحسين تحدي التسويق: (المشكلة 5)',
        para10: 'من خلال توفير رؤى حول أداء حملات التسويق، يمكن لـ "حواس" للمسوقين اتخاذ قرارات مستندة إلى البيانات وتحسين استراتيجياتهم لتحقيق نتائج أفضل.',
        sec_header9: 'مشكلة ضمان جودة البيانات: (المشكلة 6)',
        para11: 'يساعد "حواس" في ضمان جودة البيانات عن طريق اكتشاف وتصحيح التناقضات والأخطاء في البيانات، مما يضمن أن البيانات المستخدمة للتحليل والإبلاغ دقيقة.',
        sec_header10: 'الحلاول المقترحة', 
        para12: 'من خلال منتج "حواس"، يتم تقديم حلاً مبتكرًا وفعّالًا للشركات للتعامل مع التحديات الرئيسية في مجال تحليل البيانات وتحسين أدائها. إحدى أهم ميزات هذا الحلا هي التحليل المتقدم للبيانات باستخدام تقنيات الذكاء الاصطناعي. "حواس" يمكنه تحليل مجموعات كبيرة من البيانات بدقة وكفاءة، مما يوفر للشركات القدرة على استخراج رؤى قيمة وموثوقة. كما يتيح للشركات تحليل البيانات المتعلقة بالعمليات والإنتاج والعملاء، مما يساعدها على تعزيز الكفاءة التشغيلية وتحسين تجربة العملاء. بفضل توقعاته الدقيقة وواجهة المستخدم قابلة للتخصيص، يمكن أن يكون "حواس" الحلا المثلى للشركات الراغبة في تحقيق التميز التنافسي وزيادة الإنتاجية في عالم الأعمال المعاصر.',
        sec_header11: 'خلفية المشكلة في السوق',
        para13: 'في العصر الحالي، أصبحت البيانات عاملاً حاسمًا في اتخاذ القرارات للشركات والمؤسسات في المملكة العربية السعودية. حيث يتزايد حجم البيانات بشكل هائل، مما يوفر فرصة لاستخدامها لتعزيز الأداء وتوقع الاتجاهات المستقبلية. ومع ذلك، تواجه الشركات تحديات كبيرة عندما يتعلق الأمر بالبيانات، بما في ذلك: تفاوت البيانات: يتم تخزين البيانات في مصادر متعددة وتنسيقات مختلفة، مما يجعل من الصعب تكاملها وتحليلها بشكل موحد. نقص الوقت والموارد: يتطلب تحليل البيانات وقتًا وموارد كبيرة، مما يشكل تحديًا للشركات التي تعمل في بيئة تنافسية. صعوبة استخراج الرؤى: يمكن أن يكون تحويل البيانات إلى معلومات قيمة ورؤى مفيدة أمرًا معقدًا ويتطلب خبرة تحليلية عالية. تحديات الأمان: الحفاظ على أمان البيانات وحمايتها من التسريبات أمر حيوي ويتطلب إجراءات أمان صارمة. تجعل هذه التحديات من الصعب على الشركات استخدام البيانات بفعالية في اتخاذ القرارات الاستراتيجية وتحسين الأداء. هنا يأتي دور مشروع "حواس" لمعالجة هذه المشكلة.',
},

]);

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('id');

const selectedBlog = blogsData.find(blog => String(blog.id) === String(id));


return (
    <>
    <PageHeader link1="Home" link2={selectedBlog?.header} title={selectedBlog?.header} img={PageHeaderImg} />
    <section className='blog-page'>
        <div className='container'>
        <div className='blog-page-content'>
            {selectedBlog ? (
            <>
                <img src={selectedBlog.img} alt="hawas product" />
                <h1>{selectedBlog.header}</h1>

                <h4>

                </h4>

                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header1}</h2>
                        <p>{selectedBlog.para1}</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header2}</h2>
                        <p>{selectedBlog.para2}</p>
                        <p>{selectedBlog.para3}</p>
                        <p>{selectedBlog.para4}</p>
                        <p>{selectedBlog.para5}</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header4}</h2>
                        <p>{selectedBlog.para6}</p>


                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header5}</h2>
                        <p>{selectedBlog.para7}</p>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header6}</h2>
                        <p>{selectedBlog.para8}</p>
                    </div>

                    
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header7}</h2>
                        <p>{selectedBlog.para9}</p>
                    </div>

                        
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header8}</h2>
                        <p>{selectedBlog.para10}</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header9}</h2>
                        <p>{selectedBlog.para11}</p>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header10}</h2>
                        <p>{selectedBlog.para12}</p>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header11}</h2>
                        <p>{selectedBlog.para13}</p>
                    </div>

                </div>
            </>
            ) : (
            <p>Blog not found</p>
            )}
        </div>
        </div>
    </section>
    </>
);
}

export default ProductPage;
