import '../css/navbar.css';
import Logo from '../images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useRef } from "react";
import $ from 'jquery';

function Navbar() {


    
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    useEffect(() => {
        $("#open-nav").on("click", function () {
            $(".nav-eles").slideDown(400);
            $("nav").addClass("active");

            $(".nav-eles").css({ display: "flex" });
        });

        $("#close-nav").on("click", function () {
            $(".nav-eles").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-eles ul li").on("click", function () {
                $(".nav-eles").slideUp(400);
                $("nav").removeClass("active");

            });
        }
        const loadingElement = document.getElementById("loading");
        if (loadingElement) {
            loadingElement.remove();
        }
    }, []);


    return (
        <>
            <nav>
                <div className='container'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="شعار يوكونتنت" />
                        </div>

                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-eles">
                            <button id="close-nav"> <i className="las la-times"></i> </button>

                            <ul>
                                <li>
                                    <RouterLink to="/ar">
                                        الرئيسية
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/ar/about-us">
                                        من نحن
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/services">
                                        الخدمات
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/our-products">
                                        المنتجات
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/ar/blogs">
                                        المدونة
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/ar/contact-us">
                                        اتصل بنا
                                    </RouterLink>
                                </li>

                                <li>
                                    <a href="/">
                                        English
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <RouterLink to="/ar/contact-us"> اتصل بنا </RouterLink>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
