import '../css/aboutus.css';
import AboutImg from '../images/aboutus.webp'
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

function Aboutus(){

    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    
    return(
        <>
            <section className='about'>
                <div className='container'>
                    <div className='about-content'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='header'>
                                    <h3  data-aos="fade-right"> حول شركة المحتوى الفريد </h3>
                                    <h1  data-aos="fade-right"> 
                                        مرحبًا بك في <span>شركة المحتوى الفريد </span>: رائدة الاستشارات في مجال البيانات في <span>المملكة العربية السعودية</span>
                                    </h1>
                                </div>
                            </div>


                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='about-img'>
                                    <div className='image'>
                                        <img  data-aos="fade-up" src={AboutImg} />
                                        <RouterLink to="/ar/blogs">  
                                            <i class="las la-pen-alt"></i>
                                        </RouterLink>
                                    </div>

                                    <div className='text'>
                                        <p  data-aos="fade-right">
                                            اكتشف التميز في حلول البيانات مع المحتوى الفريد ، شركة استشارات بيانات متميزة مقرها في المملكة العربية السعودية.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='about-text'>
                                    <h2  data-aos="fade-up"> حلول بيانات مبتكرة مصممة حسب احتياجاتك </h2>
                                    <p  data-aos="fade-up">
                                        شركة المحتوى الفريد تقف في الصدارة في مجال الاستشارات البيانية ، وتقدم خبرة لا مثيل لها للشركات الساعية لحلول تحولية. يجلب محترفونا ذوو المهارات العالية ثروة من الخبرة إلى الطاولة ، متخصصين في مجموعة من الخدمات المتعلقة بالبيانات.
                                    </p>

                                    <RouterLink  data-aos="fade-up" to="/contact-us">
                                        تحدث إلى استشاري
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus;
