import '../css/ourproducts.css';
import PageHeader from '../PageHeader';
import pageHeaderImg from '../images/pageheaderproducts.webp';
import productImg1 from '../images/products/hawas.png';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

function OurProducts() {


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return (
        <>
            <PageHeader link1="الرئيسية" link2="المنتجات" title="منتجاتنا" img={pageHeaderImg} />
            <section className='our-products'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='header'>
                                <h2 data-aos="fade-up">حلول بيانات مبتكرة لكل احتياج تجاري</h2>
                                <h1 data-aos="fade-up"> استكشف مستقبل البيانات مع منتجات شركة المحتوى الفريد المتطورة </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='product'>
                                <img src={productImg1} alt="صورة المنتج" />
                                <h1>
                                    ثورة في تحليل البيانات والتسويق الرقمي في المملكة العربية السعودية
                                </h1>
                                <p>
                                    "حواس" هو قوة تحولية في عالم تحليل البيانات والتسويق الرقمي. مع التفاني اللافت في تمكين الشركات برؤى قائمة على البيانات...
                                </p>

                                <RouterLink to="/ar/product?id=1">عرض <i className="las la-arrow-right"></i></RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurProducts;
