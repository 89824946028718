import './css/aboutus.css';
import AboutImg from './images/aboutus.webp'
import { Link as RouterLink } from 'react-router-dom';

function Aboutus(){
    return(
        <>
            <section className='about'>
                <div className='container'>
                    <div className='about-content'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='header'>
                                    <h3  data-aos="fade-right"> About UCONTENT </h3>
                                    <h1  data-aos="fade-right"> 
                                        Welcome to <span>Unique Content </span>: Pioneering Data Consulting in <span>Saudi Arabia</span>
                                    </h1>
                                </div>
                            </div>


                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='about-img'>
                                    <div className='image'>
                                        <img  data-aos="fade-up" src={AboutImg} />
                                        <RouterLink to="/blogs">  
                                            <i class="las la-pen-alt"></i>
                                        </RouterLink>
                                    </div>

                                    <div className='text'>
                                        <p  data-aos="fade-right">
                                            Discover excellence in data solutions with Unique Content , a distinguished data consulting firm headquartered in Saudi Arabia.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='about-text'>
                                    <h2  data-aos="fade-up"> Innovative Data Solutions Tailored to Your Needs </h2>
                                    <p  data-aos="fade-up">
                                        Unique Content  stands at the forefront of data consulting, offering unparalleled expertise to businesses seeking transformative solutions. Our highly skilled professionals bring a wealth of experience to the table, specializing in a range of data-related services
                                    </p>

                                    
                                    <RouterLink  data-aos="fade-up" to="/contact-us">
                                        Talk to a consultant
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus;