import './css/ourproducts.css';
import PageHeader from './PageHeader';
import pageHeaderImg from './images/pageheaderproducts.webp';
import productImg1 from './images/products/hawas.png';
import { Link as RouterLink } from 'react-router-dom';

function OurProducts(){
    return(
        <>
            <PageHeader link1="Home" link2="Products" title="Our Products" img={pageHeaderImg} />
            <section className='our-products'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='header'>
                                <h2 data-aos="fade-up"> Innovative Data Solutions for Every Business Need </h2>
                                <h1 data-aos="fade-up">  Explore the Future of Data with unique content's Cutting-edge Products </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='product'>
                                <img src={productImg1} alt="product image" />
                                <h1>
                                Revolutionizing 
                                Data Analytics &
                                Digital Marketing 
                                in Saudi Arabia
                                </h1>
                                <p>
                                    "Hawas" is a transformative force in the world of data analytics and digital marketing. With a relentless commitment to empowering businesses with data-driven insights...
                                </p>

                                <RouterLink to="/product?id=1"> View  <i class="las la-arrow-right"></i></RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurProducts;