import './css/ourblogs.css';

import BlogImg1 from './images/blogs/blog1.jpg';
import BlogImg2 from './images/blogs/blog2.jpg';
import BlogImg3 from './images/blogs/blog3.jpg';
import BlogImg4 from './images/blogs/blog4.jpg';
import BlogImg5 from './images/blogs/blog5.webp';


import { Link as RouterLink } from 'react-router-dom';

import React, { useState,useEffect } from 'react';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';




function OurBlogs(){
    return(
        <>
            <section className='our-blogs'>
                <div className='container'>
                    <div className='our-blogs-content'>
                        <div className='header'>
                            <h3 data-aos="fade-up"> Our Blogs </h3>
                            <h1 data-aos="fade-up">  Unique Content  Chronicles: Navigating the Frontier of Data Excellence </h1>
                        </div>

                        <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                    nextEl: '.next-btn',
                    prevEl: '.prev-btn',
                    }} pagination={false}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay,Navigation,Pagination]}
                        >
                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg1} alt="blog image"/>
                                    <div className='text'>
                                        <h1> The Future of Data Analytics in Saudi Arabia: Trends and Predictions </h1>
                                        <h2> Unique Content  </h2>

                                        <p>
                                        The landscape of data analytics in Saudi Arabia is rapidly evolving, with emerging trends and technologies shaping the future of various industries...
                                        </p>

                                        <RouterLink to="/blog?id=1"> Read More <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>

                            
                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg2} alt="blog image"/>
                                    <div className='text'>
                                        <h1> Leveraging Big Data for Business Growth in Saudi Arabia </h1>
                                        <h2> Unique Content  </h2>

                                        <p>
                                        In the rapidly evolving business landscape of Saudi Arabia, leveraging big data has become a cornerstone for driving growth and maintaining competitive advantage...
                                        </p>

                                        <RouterLink to="/blog?id=2"> Read More <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>


                            
                            <SwiperSlide>
                                <div  data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg3} alt="blog image"/>
                                    <div className='text'>
                                        <h1> Social Media Marketing in Saudi Arabia: Trends, Tips, and Best Practices </h1>
                                        <h2> Unique Content  </h2>

                                        <p>
                                        In Saudi Arabia, social media has become a dynamic and essential tool for marketing, reflecting the country's high internet penetration and mobile device usage...
                                        </p>

                                        <RouterLink to="/blog?id=3"> Read More <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>



                            
                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg4} alt="blog image"/>
                                    <div className='text'>
                                        <h1> The Role of AI and Machine Learning in Data Analytics </h1>
                                        <h2> Unique Content  </h2>

                                        <p>
                                            The integration of Artificial Intelligence (AI) and Machine Learning (ML) into data analytics has marked a revolutionary shift in how we extract, process, and interpret vast amounts of data...
                                        </p>

                                        <RouterLink to="/blog?id=4"> Read More <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>


                            
                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg5} alt="blog image"/>
                                    <div className='text'>
                                        <h1> How Data Analytics and AI Improve Digital Marketing for Saudi Small and Medium Companies </h1>
                                        <h2> Unique Content  </h2>

                                        <p>
                                            For small and medium-sized companies (SMEs) in Saudi Arabia, the integration of Data Analytics and Artificial Intelligence (AI) into digital marketing strategies ...
                                        </p>

                                        <RouterLink to="/blog?id=5"> Read More <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </Swiper>

                        


                        <div data-aos="fade-up" className='blogs-footer'>
                            <h4> Choose Unique Content Now <a href="mailto:info@ucontent.com"> Let's get started </a></h4>
                        </div>
                        


                    </div>
                </div>
            </section>
        </>
    )
}

export default OurBlogs;