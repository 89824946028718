import '../css/pageheader.css';

import React from 'react';

function PageHeader(props){


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    
    return(
        <>
            <section className='page-header'>
                    <div className={`page-header-content ${props.className}`}>
                        <img src={props.img}  alt="about page background"/>
                        <div className='overlay'></div>
                        <h1> {props.title} </h1>
                        <div className='navs'>
                            <a href="#">
                                {props.link1}
                            </a>
                            <span>/</span>
                            <a href="#" className='active'>
                                {props.link2}
                            </a>
                        </div>
                    </div>
            </section>
        </>
    )
}


export default PageHeader;