import '../css/whychooseus.css';
import React from 'react';

function WhyChooseUs() {


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    return (
        <>
            <section className='why-choose-us'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='header'>
                                <h3 data-aos="fade-up">لماذا تختار شركة المحتوى الفريد </h3>

                                <h1 data-aos="fade-up">
                                    أطلق قوة بياناتك، احصل على تفوق تنافسي
                                </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i className="las la-route"></i>
                                <h1 >
                                    خبرة لا مثيل لها في توجيه البيانات
                                </h1>
                                <p>
                                    تعتبر شركة المحتوى الفريد شريكك الموثوق، يرشدك خلال تعقيدات المشهد القائم على البيانات.
                                </p>

                                <a href="mailto:info@ucontent.sa">
                                    اعرف المزيد <i className="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i className="las la-users"></i>
                                <h1>
                                    ابتكار محوري لتجاوز التوقعات العملاء
                                </h1>
                                <p>
                                    في شركة المحتوى الفريد، نعتبر نجاحك أولويتنا من خلال نهج محوري للعملاء والتفاني في الابتكار.
                                </p>

                                <a href="mailto:info@ucontent.sa">
                                    اعرف المزيد <i className="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i className="las la-chart-line"></i>
                                <h1>
                                    نمو استراتيجي مغذى برؤى البيانات
                                </h1>
                                <p>
                                    قم برفع استراتيجية بياناتك مع شركة المحتوى الفريد وابدأ في رحلة نحو النمو الاستراتيجي.
                                </p>

                                <a href="mailto:info@ucontent.sa">
                                    اعرف المزيد <i className="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;
