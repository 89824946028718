import './css/blogpage.css';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from './PageHeader';
import { Link as RouterLink } from 'react-router-dom';

import PageHeaderImg from './images/products/pageheader.webp';

import ProductImg1 from './images/products/hawas.png';


function ProductPage() {
const [blogsData, setBlogsData] = useState([
    { id: 1, header: 'Revolutionizing Data Analytics &Digital Marketing in Saudi Arabia', img: ProductImg1, sec_header1: 'The Problem', para1: 'Fragmented analytics tools and lack of actionable marketing insights. Ineffective digital marketing strategies Variety of data steams. Complex market analytics.', sec_header2: 'Our Solution', para2 :' All-in-one platform that simplifies data analytics and amplifies digital marketing effectiveness.',para3: 'The "Hawas" project is an innovative digital platform designed to provide advanced analytical solutions for companies in the Kingdom of Saudi Arabia, aiming to assist them in understanding and analyzing their data in a deeper and more precise manner.It enables companies to analyze large datasets efficiently and quickly using artificial intelligence techniques, allowing them to extract valuable insights from their data. In addition, "Hawas" can be used to improve operational performance by analyzing data related to production, supply, and other processes, increasing efficiency and reducing costs.', 
    para4: 'The platform also provides analytics on customer behavior and interactions with products or services, allowing companies to enhance the customer experience and increase satisfaction. Through the use of historical data and predictive models, "Hawas" can provide accurate predictions of future market trends and demand for products or services.', para5: '"Hawas" offers a user-friendly and customizable interface that allows users to analyze data and create custom reports. It ensures data security and confidentiality using the latest technologies in data protection.In summary, "Hawas" is a powerful tool for data analysis and making data-driven strategic decisions, offering many advantages for companies seeking to improve their performance and excel in a competitive business environment.',sec_header4 : 'Data Integration issue: (problem 1)   ', para6: 'HAWAS can integrate data from various marketing and advertising sources, such as social media platforms, advertising networks, CRM systems, and more, into a single, unified platform. This helps businesses streamline data management.',
    sec_header5: 'Data Analysis challenges: (problem 2)',para7 :'HAWAS provides tools for data analysis and visualization. Marketers can use it to gain insights into their marketing performance, customer behavior, and campaign effectiveness.', sec_header6 : 'UX and UI Issues:(problem 3)',para8 : 'HAWAS allows users to create customized reports and dashboards, making it easier to track key performance metrics and share insights with stakeholders.',
    sec_header7 : 'Target Market Identification Issue:(problem 4)', para9 : 'HAWAS can help marketers attribute conversions and sales to specific marketing channels or campaigns, allowing for more accurate ROI analysis.', sec_header8: 'Optimize Marketing Challenge: (problem 5)' , para10 : ' By providing insights into the performance of marketing campaigns, HAWAS enables marketers to make data-driven decisions and optimize their strategies for better results.', sec_header9 : 'Data Quality Assurance Issue: (problem 6)' , para11 : 'HAWAS helps ensure data quality by detecting and rectifying data discrepancies and errors, ensuring that the data used for analysis and reporting is accurate.',
    sec_header10 : 'The Proposed Solution', para12: 'Through the "Hawas" product, an innovative and effective solution is offered to companies to address the key challenges in the field of data analysis and improve their performance. One of the most important features of this solution is the advanced data analysis using artificial intelligence techniques."Hawas" can analyze large datasets with precision and efficiency, providing companies with the ability to extract valuable and reliable insights. It also allows companies to analyze data related to operations, production, and customers, helping them enhance operational efficiency and improve the customer experience. With its accurate predictions and customizable user interface, "Hawas" can be the ideal solution for companies looking to achieve competitive excellence and increase productivity in the contemporary business world.',
    sec_header11 : 'Background of the Problem in the Market', para13: 'In the current era, data has become a crucial factor in decision-making for companies and institutions in the Kingdom of Saudi Arabia. The volume of data is growing immensely, providing an opportunity to use it to enhance performance and predict future trends. However, significant challenges face companies when it comes to data, including:Data Disparity: Data is stored in multiple sources and different formats, making it challenging to integrate and analyze uniformly.Lack of Time and Resources: Analyzing data requires substantial time and resources, posing a challenge for companies operating in a competitive environment.Difficulty in Extracting Insights: Transforming data into valuable information and useful insights can be complex and requires high analytical expertise.Security Challenges: Maintaining data security and protecting it from leaks is vital and demands strict security measures.These challenges make it difficult for companies to effectively use data for strategic decision-making and performance improvement. This is where the "Hawas" project comes into play to address this problem.',

},

]);

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('id');

const selectedBlog = blogsData.find(blog => String(blog.id) === String(id));


return (
    <>
    <PageHeader link1="Home" link2={selectedBlog?.header} title={selectedBlog?.header} img={PageHeaderImg} />
    <section className='blog-page'>
        <div className='container'>
        <div className='blog-page-content'>
            {selectedBlog ? (
            <>
                <img src={selectedBlog.img} alt="hawas product" />
                <h1>{selectedBlog.header}</h1>

                <h4>

                </h4>

                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header1}</h2>
                        <p>{selectedBlog.para1}</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header2}</h2>
                        <p>{selectedBlog.para2}</p>
                        <p>{selectedBlog.para3}</p>
                        <p>{selectedBlog.para4}</p>
                        <p>{selectedBlog.para5}</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header4}</h2>
                        <p>{selectedBlog.para6}</p>


                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header5}</h2>
                        <p>{selectedBlog.para7}</p>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header6}</h2>
                        <p>{selectedBlog.para8}</p>
                    </div>

                    
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header7}</h2>
                        <p>{selectedBlog.para9}</p>
                    </div>

                        
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header8}</h2>
                        <p>{selectedBlog.para10}</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header9}</h2>
                        <p>{selectedBlog.para11}</p>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header10}</h2>
                        <p>{selectedBlog.para12}</p>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <h2>{selectedBlog.sec_header11}</h2>
                        <p>{selectedBlog.para13}</p>
                    </div>

                </div>
            </>
            ) : (
            <p>Blog not found</p>
            )}
        </div>
        </div>
    </section>
    </>
);
}

export default ProductPage;
