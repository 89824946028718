import './css/whychooseus.css';

function WhyChooseUs(){
    return(
        <>
            <section className='why-choose-us'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='header'>
                                <h3 data-aos="fade-up"> Why Choose Unique Content ? </h3>

                                <h1 data-aos="fade-up">
                                    Unleash the Power of Your Data, Gain a Competitive Edge
                                </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i class="las la-route"></i>
                                <h1 > 
                                    Unparalleled Expertise in Data Navigation
                                </h1>
                                <p>
                                    Unique Content  serves as your trusted partner, guiding you through the complexities of the data-driven landscape. 
                                </p>

                                <a href="mailto:info@ucontent.sa">
                                    Know More <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i class="las la-users"></i>
                                <h1> 
                                Client-Centric Innovation for Exceeding Expectations
                                </h1>
                                <p>
                                At Unique Content , we prioritize your success through a client-centric approach and a commitment to innovation. Selecting us means choosing a partner dedicated to exceeding your expectations. </p>

                                <a href="mailto:info@ucontent.sa">
                                    Know More <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i class="las la-chart-line"></i>
                                <h1> 
                                Strategic Growth Fueled by Data Insights
                                </h1>
                                <p>
                                Elevate your data strategy with Unique Content  and embark on a journey toward strategic growth. Our commitment to unlocking the true potential of your data goes beyond mere analytics; it is about harnessing insights that fuel your business's evolution. </p>

                                <a href="mailto:info@ucontent.sa">
                                    Know More <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;