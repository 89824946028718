import './App.css';
import { Routes, Route } from "react-router-dom";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Aboutus from './components/Aboutus';
import WhyChooseUs from './components/WhyChooseUs';
import OurSolutions from './components/OurSolutions';
import OurServices from './components/OurServices';
import OurBlogs from './components/OurBlogs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import OurProducts from './components/OurProducts';

import PageHeader from './components/PageHeader';
import BlogPage from './components/BlogPage';
import ProductPage from './components/ProductPage';



import NavbarAr from './components/ar/Navbar';
import LandingAr from './components/ar/Landing';
import AboutusAr from './components/ar/Aboutus';
import WhyChooseUsAr from './components/ar/WhyChooseUs';
import OurSolutionsAr from './components/ar/OurSolutions';
import OurServicesAr from './components/ar/OurServices';
import OurBlogsAr from './components/ar/OurBlogs';
import ContactAr from './components/ar/Contact';
import FooterAr from './components/ar/Footer';
import OurProductsAr from './components/ar/OurProducts';

import PageHeaderAr from './components/ar/PageHeader';
import BlogPageAr from './components/ar/BlogPage';
import ProductPageAr from './components/ar/ProductPage';


import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';


import pageHeaderImg1 from './components/images/1.webp';
import pageHeaderImg2 from './components/images/2.webp';
import pageHeaderImg3 from './components/images/3.webp';
import pageHeaderImg4 from './components/images/4.webp';

function App() {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, [])
    
return (
    <div className="App">

            <Routes>

            <Route path="/" element={
                    <>
                                <Navbar />

                        <Landing />
                        <Aboutus />
                        <WhyChooseUs />
                        <OurSolutions />
                        <OurServices />
                        <OurBlogs />
                        <Contact />
                        <Footer />


                    </>
                } />

                <Route path="/about-us" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="About us" title="About Us" img={pageHeaderImg1} />
                        <Aboutus />
                        <WhyChooseUs />
                        <OurSolutions />
                        <Contact />
                        <Footer />

                    </>
                } />


            <Route path="/services" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="Services" title="Services" img={pageHeaderImg2} />
                        <OurServices />
                        <OurSolutions />
                        <Contact />
                        <Footer />

                    </>
                } />
                

            


                
            <Route path="/our-products" element={
                    <>
                                <Navbar />

                        <OurProducts />
                        <Contact />
                        <Footer />

                    </>
                } />


            
            <Route path="/blogs" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="blogs" title="Our Blogs" img={pageHeaderImg3} />
                        <OurBlogs />
                        <Contact />
                        <Footer />

                    </>
                } />

            <Route path="/contact-us" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="Contact Us" title="Contact Us" img={pageHeaderImg4} />
                        <Contact />
                        <Footer />

                    </>
                } />


            <Route path="/blog" element={
                    <>
                                <Navbar />

                        <BlogPage />
                        <Footer />

                    </>
                } />


                
            <Route path="/product" element={
                    <>
                                <Navbar />

                        <ProductPage />
                        <Footer />

                    </>
                } />







                
            <Route path="/ar/" element={
                    <>
                        <LandingAr />
                        <NavbarAr />
                        <AboutusAr />
                        <WhyChooseUsAr />
                        <OurSolutionsAr />
                        <OurServicesAr />
                        <OurBlogsAr />
                        <ContactAr />
                        <FooterAr />


                    </>
                } />

                <Route path="/ar/about-us" element={
                    <>
                                <NavbarAr />

                        <PageHeaderAr link1="الرئيسية" link2="عن الشركة" title="عن الشركة" img={pageHeaderImg1} />
                        <AboutusAr />
                        <WhyChooseUsAr />
                        <OurSolutionsAr />
                        <ContactAr />
                        <FooterAr />

                    </>
                } />


            <Route path="/ar/services" element={
                    <>
                                <NavbarAr />

                        <PageHeaderAr link1="الرئيسية" link2="الخدمات" title="الخدمات" img={pageHeaderImg2} />
                        <OurServicesAr />
                        <OurSolutionsAr />
                        <ContactAr />
                        <FooterAr />

                    </>
                } />
                

            


                
            <Route path="/ar/our-products" element={
                    <>
                                <NavbarAr />

                        <OurProductsAr />
                        <ContactAr />
                        <FooterAr />

                    </>
                } />


            
            <Route path="/ar/blogs" element={
                    <>
                                <NavbarAr />

                        <PageHeaderAr link1="الرئيسية" link2="المقالات" title="المقالات" img={pageHeaderImg3} />
                        <OurBlogsAr />
                        <ContactAr />
                        <FooterAr />

                    </>
                } />

            <Route path="/ar/contact-us" element={
                    <>
                                <NavbarAr />

                        <PageHeaderAr link1="الرئيسية" link2="تواصل معنا" title="تواصل معنا" img={pageHeaderImg4} />
                        <ContactAr />
                        <FooterAr />

                    </>
                } />


            <Route path="/ar/blog" element={
                    <>
                                <NavbarAr />

                        <BlogPageAr />
                        <FooterAr />

                    </>
                } />


                
            <Route path="/ar/product" element={
                    <>
                                <NavbarAr />

                        <ProductPageAr />
                        <FooterAr />

                    </>
                } />

        </Routes>


        
    </div>
);
}

export default App;
