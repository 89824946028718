import '../css/ourservices.css';

import ServiceImg1 from '../images/services/service1.webp';
import ServiceImg2 from '../images/services/service2.webp';
import ServiceImg3 from '../images/services/service3.webp';
import ServiceImg4 from '../images/services/service4.webp';
import ServiceImg5 from '../images/services/service5.webp';
import ServiceImg6 from '../images/services/service6.webp';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

function OurServices(){


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    
    return(
        <section className='our-services'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                        <div className='header'>
                            <h1 data-aos="fade-up"> خدماتنا <span> الشاملة </span>  </h1>

                            <p data-aos="fade-up">
                                اكتشف مجموعة من الحلول المصممة خصيصًا في Unique Content، حيث نعيد تعريف المعايير للتميز في استشارات البيانات. تتجاوز خدماتنا الحدود التقليدية، مقدمة حلاً مبتكرًا لتحليل البيانات، وإدارة البيانات بعناية، وتخطيط البيانات الاستراتيجي.
                            </p>

                            <div data-aos="fade-up">
                                <RouterLink  to="/ar/blogs">
                                    مدونتنا
                                </RouterLink>

                                <RouterLink to="/ar/services">
                                    خدماتنا  <i class="las la-long-arrow-alt-right"></i>
                                </RouterLink>
                            </div>
                        </div>
                    </div>

                    
                    <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="100" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg1} alt="صورة الخدمة" />
                                <h1>إدارة البيانات</h1>
                                <p>يمكن لـ Unique Content مساعدة المؤسسات في تصميم وتنفيذ أنظمة وعمليات فعّالة لإدارة البيانات. قد تتضمن هذه المهام مثل تنظيف البيانات وتكاملها ونمذجة البيانات.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="300" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg2} alt="صورة الخدمة" />
                                <h1>تحليلات البيانات</h1>
                                <p>يمكن لـ Unique Content مساعدة المؤسسات في تحليل وتفسير بياناتها لاكتساب رؤى واتخاذ قرارات مستنيرة. قد تشمل ذلك تصور البيانات والتحليل الإحصائي.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="500" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg3} alt="صورة الخدمة" />
                                <h1>الذكاء الاصطناعي وتعلم الآلة</h1>
                                <p>يمكن لـ Unique Content دعم المؤسسات في الاستفادة من تقنيات متقدمة مثل الذكاء الاصطناعي وتعلم الآلة لتأتي بعمليات التشغيل وتحسين عمليات اتخاذ القرارات.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="700" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg4} alt="صورة الخدمة" />
                                <h1>حوكمة البيانات</h1>
                                <p>يمكن لـ Unique Content مساعدة المؤسسات في إقامة وصيانة سياسات وإجراءات فعّالة لحوكمة البيانات. قد تشمل ذلك أمان البيانات وخصوصية البيانات وأخلاقيات البيانات.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="900" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg5} alt="صورة الخدمة" />
                                <h1>الخدمات الإحصائية</h1>
                                <p>تشمل الخدمات الإحصائية التي يقدمها Unique Content، ولكن لا تقتصر على ذلك، جمع البيانات، ومعالجة وتنظيف البيانات، والتحليل الإحصائي، وتصور البيانات، وكتابة التقارير، والعروض التقديمية.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="1200" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg6} alt="صورة الخدمة" />
                                <h1>التدريب والتطوير</h1>
                                <p>يمكن لـ Unique Content تقديم برامج تدريب وتطوير لمساعدة المؤسسات في بناء المهارات والمعرفة اللازمة للعمل مع البيانات بفعالية. قد تشمل ذلك ورش العمل.</p>
                            </div>
                        </div>

                    </div>
                </div>

                </div>
            </div>
        </section>
    )
}

export default OurServices;
