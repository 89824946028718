import '../css/contact.css';
import React from 'react';

function Contact(){

    
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);
    return(
        <>
            <section className='contact'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div  className='contact-content'>
                                <div data-aos="fade-right" className='email-info'>
                                    <i class="lar la-envelope"></i>

                                    <div>
                                        <h3> قل مرحبًا </h3>
                                        <a href="mailto:info@ucontent.sa">
                                            info@ucontent.sa
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-content'>
                                <div data-aos="fade-left" className='form'>
                                    <h1> 
                                        بحاجة لمساعدة؟
                                    </h1>
                                    <p>
                                        تواصل مع أوفر خدمات تكنولوجيا المعلومات الموثوقة في العالم.
                                    </p>

                                    <form action="mailto:info@ucontent.sa">
                                        <div className='inputs'>
                                            <input placeholder='الاسم * ' type='text' required name="name" />
                                            <input placeholder='البريد الإلكتروني * ' type='email' required name="email" />
                                        </div>

                                        <div className='inputs'>
                                            <input placeholder='الموضوع * ' type='text' required name="subject" />
                                        </div>

                                        <div className='inputs'>
                                            <textarea placeholder='الرسالة * ' required name="message">

                                            </textarea>
                                        </div>

                                        <button> إرسال الرسالة </button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <iframe data-aos="zoom-in" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.3357822099174!2d46.644123524620255!3d24.818188046947775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee40eefcb6a9d%3A0xbc4308498520f827!2z2LfYsdmK2YIg2KPZhtizINin2KjZhiDZhdin2YTZg9iMINin2YTYsdmK2KfYtiDYp9mE2LPYudmI2K_Zitip!5e0!3m2!1sar!2seg!4v1702329700528!5m2!1sar!2seg" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;
