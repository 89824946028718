import '../css/ourblogs.css';

import BlogImg1 from  '../images/blogs/blog1.jpg';
import BlogImg2 from  '../images/blogs/blog2.jpg';
import BlogImg3 from  '../images/blogs/blog3.jpg';
import BlogImg4 from  '../images/blogs/blog4.jpg';
import BlogImg5 from  '../images/blogs/blog5.webp';


import { Link as RouterLink } from 'react-router-dom';

import React, { useState, useEffect } from 'react';


import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper';

function OurBlogs() {


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    return (
        <>
            <section className='our-blogs'>
                <div className='container'>
                    <div className='our-blogs-content'>
                        <div className='header'>
                            <h3 data-aos="fade-up"> مدوناتنا </h3>
                            <h1 data-aos="fade-up"> سجلات المحتوى الفريد: توجيه حدود التميز في مجال التحليلات البيانية </h1>
                        </div>

                        <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                        }} pagination={false}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Navigation, Pagination]}
                        >
                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg1} alt="صورة المدونة" />
                                    <div className='text'>
                                        <h1> مستقبل تحليل البيانات في المملكة العربية السعودية: الاتجاهات والتوقعات </h1>
                                        <h2> محتوى فريد </h2>

                                        <p>
                                        يتطور مشهد تحليل البيانات في المملكة العربية السعودية بسرعة، مع ظهور اتجاهات وتقنيات جديدة تشكل مستقبل مختلف الصناعات...
                                        </p>

                                        <RouterLink to="/ar/blog?id=1"> اقرأ المزيد <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg2} alt="صورة المدونة" />
                                    <div className='text'>
                                        <h1> الاستفادة من  البيانات الكبيرة لتحقيق نمو الأعمال في المملكة العربية السعودية </h1>
                                        <h2> محتوى فريد </h2>

                                        <p>
                                        في المشهد العملي المتطور بسرعة في المملكة العربية السعودية، أصبحت الاستفادة from  البيانات الكبيرة ركيزة أساسية لتحقيق النمو والحفاظ على الميزة التنافسية...
                                        </p>

                                        <RouterLink to="/ar/blog?id=2"> اقرأ المزيد <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg3} alt="صورة المدونة" />
                                    <div className='text'>
                                        <h1> تسويق وسائل التواصل الاجتماعي في المملكة العربية السعودية: الاتجاهات، النصائح، وأفضل الممارسات </h1>
                                        <h2> محتوى فريد </h2>

                                        <p>
                                        في المملكة العربية السعودية، أصبحت وسائل التواصل الاجتماعي أداة ديناميكية وأساسية للتسويق، مع انعكاس انتشار الإنترنت العالي واستخدام الأجهزة المحمولة...
                                        </p>

                                        <RouterLink to="/ar/blog?id=3"> اقرأ المزيد <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg4} alt="صورة المدونة" />
                                    <div className='text'>
                                        <h1> دور الذكاء الاصطناعي وتعلم الآلة في تحليل البيانات </h1>
                                        <h2> محتوى فريد </h2>

                                        <p>
                                        أدت إدماج الذكاء الاصطناعي (AI) وتعلم الآلة (ML) في تحليل البيانات إلى تحول ثوري في كيفية استخراجنا ومعالجتنا وتفسيرنا لكميات كبيرة من  البيانات...
                                        </p>

                                        <RouterLink to="/ar/blog?id=4"> اقرأ المزيد <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div data-aos="zoom-in" className='blog'>
                                    <img src={BlogImg5} alt="صورة المدونة" />
                                    <div className='text'>
                                        <h1> كيف تحسن تحليل البيانات والذكاء الاصطناعي التسويق الرقمي للشركات الصغيرة والمتوسطة في المملكة العربية السعودية </h1>
                                        <h2> محتوى فريد </h2>

                                        <p>
                                            بالنسبة للشركات الصغيرة والمتوسطة (SMEs) في المملكة العربية السعودية، أدى دمج تحليل البيانات والذكاء الاصطناعي (AI) في استراتيجيات التسويق الرقمي...
                                        </p>

                                        <RouterLink to="/ar/blog?id=5"> اقرأ المزيد <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>

                        <div data-aos="fade-up" className='blogs-footer'>
                            <h4> اختر المحتوى الفريد الآن <a href="mailto:info@ucontent.com"> لنبدأ </a></h4>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OurBlogs;
