import '../css/landing.css';

import LandingVid from '../videos/landing.mp4';

import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

function Landing() {


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
            try {
                // استيراد ورقة الأنماط الخاصة باللغة العربية
                const arStyles = await import('../css/Ar.css');
                // تطبيق الأنماط إذا كانت مطلوبة
                if (arStyles.default) {
                    arStyles.default();
                }
            } catch (error) {
                console.error('حدث خطأ أثناء استيراد أنماط اللغة العربية:', error);
            }
        }
    };
    

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return (
        <>
            <section data-aos="zoom-in" className='landing'>
                <video src={LandingVid} loop autoPlay muted></video>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='landing-content'>
                        <h1 data-aos-delay="1200" data-aos="fade-up">المحتوى فريد</h1>
                        <h2 data-aos-delay="1200" data-aos="fade-up">استراتيجية للأفق الجديد</h2>
                        <p data-aos-delay="1200" data-aos="fade-up">
                            اختبر الإمكانات التحولية للبيانات من خلال خدمات الاستشارات غير المسبوقة لدينا. قم بدفع عملك للأمام وقم بتعزيز عمليات اتخاذ القرار بدقة.
                        </p>

                        <div data-aos-delay="1200" data-aos="fade-up">
                            <RouterLink to='/ar/services'>خدماتنا</RouterLink>

                            <a href="mailto:info@ucontent.sa">
                                <i className="las la-play-circle"></i>
                                كيفية البدء؟
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;
