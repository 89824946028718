import './css/ourservices.css';

import ServiceImg1 from './images/services/service1.webp';
import ServiceImg2 from './images/services/service2.webp';
import ServiceImg3 from './images/services/service3.webp';
import ServiceImg4 from './images/services/service4.webp';
import ServiceImg5 from './images/services/service5.webp';
import ServiceImg6 from './images/services/service6.webp';
import { Link as RouterLink } from 'react-router-dom';

function OurServices(){
    return(
        <section className='our-services'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                        <div className='header'>
                            <h1 data-aos="fade-up"> Our Comprehensive <span> Services </span>  </h1>

                            <p data-aos="fade-up">
                                Discover a spectrum of tailored solutions at Unique Content , where we redefine the benchmarks for excellence in data consulting. Our services go beyond conventional boundaries, offering innovative data analytics, meticulous data management, and strategic data planning
                            </p>

                            <div data-aos="fade-up">
                                <RouterLink  to="/blogs">
                                    Our Blog
                                </RouterLink>

                                <RouterLink to="/services">
                                    Our Services  <i class="las la-long-arrow-alt-right"></i>
                                </RouterLink>
                            </div>
                        </div>
                    </div>

                    
                    <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="100" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg1} alt="service image" />
                                <h1>Data Management</h1>
                                <p>Unique Content can assist organizations in designing and implementing effective data management systems and processes. This may involve tasks such as data cleansing, data integration, and data modeling.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="300" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg2} alt="service image" />
                                <h1>Data Analytics</h1>
                                <p>Unique Content can aid organizations in analyzing and interpreting their data to gain insights and make informed decisions. This could encompass data visualization and statistical analysis.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="500" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg3} alt="service image" />
                                <h1>AI And Machine Learning</h1>
                                <p>Unique Content can support organizations in leveraging advanced technologies such as Artificial Intelligence and Machine Learning to automate processes and optimize decision-making.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="700" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg4} alt="service image" />
                                <h1>Data Governance</h1>
                                <p>Unique Content can assist organizations in establishing and maintaining effective data governance policies and procedures. This may encompass data security, data privacy, and data ethics.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="900" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg5} alt="service image" />
                                <h1>Statistical Services</h1>
                                <p>Statistical services offered by Unique Content include, but are not limited to, data collection, data processing and cleaning, statistical analysis, data visualization, report writing, and presentation.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="1200" data-aos="zoom-in" className='service'>
                                <img src={ServiceImg6} alt="service image" />
                                <h1>Training And Development</h1>
                                <p>Unique Content can provide training and development programs to help organizations build the skills and knowledge required to work with data effectively. This may involve workshops.</p>
                            </div>
                        </div>

                    </div>
                </div>

                </div>
            </div>
        </section>
    )
}

export default OurServices;