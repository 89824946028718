import './css/footer.css';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
function Footer(){

    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
    }, [location.pathname]); // Re-run this effect when the pathname changes




    return(
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <div className='logo'>
                                <img src={Logo} alt="logo uc content" />
                            </div>

                            <p>
                                Unique Content Information Technology Company
                            </p>
                            <a href="mailto:info@ucontent.sa">   info@ucontent.sa </a>
                            <a href="https://ucontent.sa"> www.ucontent.sa </a>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> Quick Links </h1>
                            <ul>
                            <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink to="/about-us">
                                        About Us
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                        Services
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/our-products">
                                        Products
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/blogs">
                                        Blogs
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        Contact Us
                                    </RouterLink>
                                </li>
                                
                                <li>
                                    <RouterLink to="/ar">
                                        العربية
                                    </RouterLink>
                                </li>
                                


                            </ul>
                        </div>
                    </div>


                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> Services </h1>
                            <ul>
                            <li>
                                    <RouterLink to="/services">
                                    Data Management
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink to="/services">
                                    Data Analytics

                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                    AI And Machine Learning

                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                    Data Governance

                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/services">
                                    Statistical Services

                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                    Training And Development
                                    </RouterLink>
                                </li>
                                

                            </ul>
                        </div>
                    </div>



                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> Blogs </h1>
                            <ul>
                            <li>
                                    <RouterLink to="/blog?id=1">
                                        Trends and Predictions
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink to="/blog?id=2">
                                    Leveraging Big Data 

                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/blog?id=3">
                                    Social Media Marketing 

                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/blog?id=4">
                                    The Role of AI 

                                    </RouterLink>
                                </li>
                                
                                <li>
                                    <RouterLink to="/blog?id=5">
                                    How Data Analytics and AI Improve

                                    </RouterLink>
                                </li>
                                

                            </ul>
                        </div>
                    </div>



                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='copy-right'>
                            <h3> © 2023 UCONTENT. All Rights Reserved. </h3>

                            <div className='socials'>
                                <a target='_blank' href="https://twitter.com/UcontentSA">
                                    <i class="lab la-twitter"></i>
                                </a>

                                <a target='_blank' href="https://www.linkedin.com/company/ucontentsa/">
                                    <i class="lab la-linkedin-in"></i>
                                </a>
                                
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </footer>
    )   
}

export default Footer;